import React from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { formatDate, formatTime } from '../../Utils';
import { DebtorClaimEvent } from '../../../api/client';
import currencyFormatter from 'currency-formatter';
import DebtorClaimEventDetailsTooltip from '../containers/DebtorClaimEventDetailsTooltip';

interface Props {
  event: DebtorClaimEvent;
  claimSystem: String;
  debtorId: String;
}

export default function DebtorClaimEventComponent(props: Props) {
  return (
    <React.Fragment>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px',
      }}>
        <Tooltip title={formatTime(props.event.eventDate)} placement="left-start">
          <Typography sx={{
            marginRight: '30px',
          }}>
            {formatDate(props.event.eventDate)}
          </Typography>
        </Tooltip>
        <Box sx={{
          minWidth: '40px'
        }}>
          <DebtorClaimEventDetailsTooltip debtorId={props.debtorId!} claimSystem={props.claimSystem} referenceNumber={props.event.referenceNumber} eventId={props.event.eventId!} eventType={props.event.eventType!} eventName={props.event.eventName} eventTooltip={props.event.tooltip!} />
        </Box>
        <Box sx={{
          flexGrow: 2,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}>
          <Typography>
            {props.event.description}
          </Typography>
        </Box>
        <Typography sx={{
          fontWeight: 'bold',
          marginLeft: '10px'
        }}>
          {props.event.amount ? currencyFormatter.format(props.event.amount, { code: 'NOK' }) : ''}
        </Typography>
      </Box>
      <Divider />
    </React.Fragment>
  )
};
