import { Action, Middleware, MiddlewareAPI, Dispatch } from 'redux';

export interface FetchConfig {
  init?: any;
  path: string;
  method?: 'DELETE' | 'GET' | 'POST' | 'PUT';
  headers?: any;
  body?: any;
  stream?: boolean;
  success(json: any);
  failure(error: string);
  signal: AbortSignal | undefined;
}

const fetchMiddleware: Middleware = <S = any, D extends Dispatch = Dispatch>(store: MiddlewareAPI<D, S>) =>
  (next: Dispatch) => <A extends Action>(action: A & { fetchConfig: FetchConfig }): A | void => {

    if (!action || !action.fetchConfig) {
      return next(action);
    }

    const config = action.fetchConfig;
    store.dispatch(config.init);

    const path = config.path || '/';
    const method = config.method || 'GET';
    const streamheaders = config.headers || { Accept: 'application/octet-stream', Authorization: `Bearer ${localStorage.getItem('accessToken')}`, 'Content-Type': 'application/octet-stream' };
    const headers = config.headers || { Accept: 'application/json', Authorization: `Bearer ${localStorage.getItem('accessToken')}`, 'Content-Type': 'application/json' };
    const body = config.body;
    const successHandler = config.success;
    const failureHandler = config.failure;

    fetch(path, {
      method: method,
      headers: config.stream === true ? streamheaders : headers,
      body: JSON.stringify(body),
      signal: config.signal
    })
      .then((response: Response) => {
        if (response.status === 204) {
          return null;
        }
        if (config.stream === true) {
          return response.blob();
        } else {
          return response.json();
        }
      })
      .then(json => successHandler(json))
      .catch(error => failureHandler(error.name));
  };

export default fetchMiddleware;