import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { ClaimEventType } from '../../../api/client';
import DebtorClaimEventIcon from '../../debtorClaimEvents/presentation/DebtorClaimEventIcon';
import DebtorClaimEventDetails from './DebtorClaimEventDetails';
import TooltipContent from '../presentation/TooltipContent';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { actionCreators as DebtorClaimDownloadStoreActionCreators } from '../../../store/DebtorClaimDownloadStore';
import { actionCreators as DebtorLeapClaimsStoreActionCreators } from '../../../store/DebtorLeapClaimsStore';
import { colors } from './../../../colors';

interface PropsFromDispatch {
  downloadDebtorClaimPDF: typeof DebtorClaimDownloadStoreActionCreators.downloadDebtorClaimPDF;
  downloadLeapReceipt: typeof DebtorLeapClaimsStoreActionCreators.downloadLeapReceipt;
  downloadLeapClaimCredit: typeof DebtorLeapClaimsStoreActionCreators.downloadLeapClaimCredit;
  downloadLeapInvoice: typeof DebtorLeapClaimsStoreActionCreators.downloadLeapInvoice;
  downloadLeapDebtCollectionWarning: typeof DebtorLeapClaimsStoreActionCreators.downloadLeapDebtCollectionWarning;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.marble,
    color: colors.black,
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    fontSize: '0.875rem',
    fontWeight: '500',
    maxWidth: '600px',
    marginTop: '4px',
    padding: '10px'
  },
}));

interface InjectedProps {
  claimSystem: string;
  debtorId: string;
  eventId: string;
  referenceNumber: string;
  eventType: ClaimEventType;
  eventName: string;
  eventTooltip: { [key: string]: string; } | undefined;
}

type ComponentProps = PropsFromDispatch & InjectedProps;

class DebtorClaimEventDetailsTooltip extends React.Component<ComponentProps> {

  downloadClaimPDF = () => {
    this.props.downloadDebtorClaimPDF(this.props.debtorId, this.props.eventId, this.props.eventType);
  }
  downloadClaimReceiptPDF = () => {
    this.props.downloadLeapReceipt(this.props.referenceNumber);
  }
  downloadLeapInvoicePDF = () => {
    this.props.downloadLeapInvoice(this.props.referenceNumber);
  }
  downloadLeapClaimCreditPDF = () => {
    this.props.downloadLeapClaimCredit(this.props.referenceNumber);
  }
  downloadLeapDebtCollectionWarningPDF = () => {
    this.props.downloadLeapDebtCollectionWarning(this.props.referenceNumber);
  }

  public render() {

    const disableHover = (
      [
        ClaimEventType.PaymentInformationSentBySms,
        ClaimEventType.PaymentInformationSentByEmail,
        ClaimEventType.ObjectionRegistered
      ].indexOf(this.props.eventType!) === -1

      && !this.props.eventTooltip
    );

    const enableDownload = (
      [
        ClaimEventType.Letter,
        ClaimEventType.ElectronicInvoice,
        ClaimEventType.InvoiceSmsReceived,
        ClaimEventType.InvoiceSmsNotReceived,
        ClaimEventType.DigiPostInvoice,
        ClaimEventType.DigiPostDebtCollectionWarning,
      ].indexOf(this.props.eventType!) !== -1
      ||
      ([ClaimEventType.DebtCollectionWarningLetter, ClaimEventType.DigiPostDebtCollectionWarning].indexOf(this.props.eventType!) !== -1 && this.props.claimSystem !== "leap")
    );

    const creditDownload = (
      [
        ClaimEventType.Credit,
      ].indexOf(this.props.eventType!) !== -1 && this.props.claimSystem === 'leap' 
    );

    const receiptDownload = (
      [
        ClaimEventType.FullyPaidReceiptAvailable
      ].indexOf(this.props.eventType!) !== -1
    );

    const downloadType = receiptDownload ? this.downloadClaimReceiptPDF :
      creditDownload && this.props.claimSystem === 'leap' ? this.downloadLeapClaimCreditPDF :
        enableDownload ? this.props.claimSystem === 'leap' ? this.props.eventName === "DebtCollectionDocumentDispatched" ?
          this.downloadLeapDebtCollectionWarningPDF : this.downloadLeapInvoicePDF
          : this.downloadClaimPDF : undefined;

    return (
      <HtmlTooltip
        title={
          this.props.eventTooltip && this.props.eventTooltip !== undefined ?
          Object.keys(this.props.eventTooltip!).map((key) => (<TooltipContent key={key} header={key} content={this.props.eventTooltip![key]} /> )) :
          <DebtorClaimEventDetails id={this.props.eventId} />
        }
        disableHoverListener={disableHover}
        placement="bottom-start"
        enterDelay={100}
        leaveDelay={200}
        PopperProps={{
          style: {
            opacity: 1
          }
        }}
      >
        <Box onClick={downloadType} sx={{
          display: 'inline',
          ...((enableDownload || receiptDownload || creditDownload ) && { cursor: 'pointer' })
        }}>
          <DebtorClaimEventIcon eventType={this.props.eventType} />
        </Box>
      </HtmlTooltip>
    );
  }
}

const mapDispatchToProps: PropsFromDispatch = {
  downloadDebtorClaimPDF: DebtorClaimDownloadStoreActionCreators.downloadDebtorClaimPDF,
  downloadLeapReceipt: DebtorLeapClaimsStoreActionCreators.downloadLeapReceipt,
  downloadLeapClaimCredit: DebtorLeapClaimsStoreActionCreators.downloadLeapClaimCredit,
  downloadLeapInvoice: DebtorLeapClaimsStoreActionCreators.downloadLeapInvoice,
  downloadLeapDebtCollectionWarning: DebtorLeapClaimsStoreActionCreators.downloadLeapDebtCollectionWarning, 
};

export default compose(
  connect(null, mapDispatchToProps)
)(DebtorClaimEventDetailsTooltip);
