import { union } from 'lodash/array';
import { Reducer } from 'redux';
import { AppThunkAction } from '../store';
import { ActionTypes as NotificationsStoreActionTypes, AddNotificationAction } from './NotificationsStore';
import { FetchConfig } from './middlewares/FetchMiddleware';
import { DebtorClaimEvent, LeapClaim } from '../api/client';

export enum ActionTypes {
  GET_DEBTOR_LEAP_CLAIMS = '@@leapClaims/GET_DEBTOR_LEAP_CLAIMS',
  GET_DEBTOR_LEAP_CLAIMS_REQUEST = '@@leapClaims/GET_DEBTOR_LEAP_CLAIMS_REQUEST',
  GET_DEBTOR_LEAP_CLAIMS_SUCCESS = '@@leapClaims/GET_DEBTOR_LEAP_CLAIMS_SUCCESS',
  GET_DEBTOR_LEAP_CLAIMS_FAILURE = '@@leapClaims/GET_DEBTOR_LEAP_CLAIMS_FAILURE',

  GET_DEBTOR_LEAP_CLAIM_CREDIT_DOWNLOAD = '@@leapClaims/GET_DEBTOR_LEAP_CLAIM_CREDIT_DOWNLOAD',
  GET_DEBTOR_LEAP_CLAIM_CREDIT_DOWNLOAD_REQUEST = '@@leapClaims/GET_DEBTOR_LEAP_CLAIM_CREDIT_DOWNLOAD_REQUEST',
  GET_DEBTOR_LEAP_CLAIM_CREDIT_DOWNLOAD_SUCCESS = '@@leapClaims/GET_DEBTOR_LEAP_CLAIM_CREDIT_DOWNLOAD_SUCCESS',
  GET_DEBTOR_LEAP_CLAIM_CREDIT_DOWNLOAD_FAILURE = '@@leapClaims/GET_DEBTOR_LEAP_CLAIM_CREDIT_DOWNLOAD_FAILURE',

  GET_DEBTOR_LEAP_DEBTCOLLECTIONWARNING_DOWNLOAD = '@@leapClaims/GET_DEBTOR_LEAP_DEBTCOLLECTIONWARNING_DOWNLOAD',
  GET_DEBTOR_LEAP_DEBTCOLLECTIONWARNING_DOWNLOAD_REQUEST = '@@leapClaims/GET_DEBTOR_LEAP_DEBTCOLLECTIONWARNING_DOWNLOAD_REQUEST',
  GET_DEBTOR_LEAP_DEBTCOLLECTIONWARNING_DOWNLOAD_SUCCESS = '@@leapClaims/GET_DEBTOR_LEAP_DEBTCOLLECTIONWARNING_DOWNLOAD_SUCCESS',
  GET_DEBTOR_LEAP_DEBTCOLLECTIONWARNING_DOWNLOAD_FAILURE = '@@leapClaims/GET_DEBTOR_LEAP_DEBTCOLLECTIONWARNING_DOWNLOAD_FAILURE',

  GET_DEBTOR_LEAP_INVOICE_DOWNLOAD = '@@leapClaims/GET_DEBTOR_LEAP_INVOICE_DOWNLOAD',
  GET_DEBTOR_LEAP_INVOICE_DOWNLOAD_REQUEST = '@@leapClaims/GET_DEBTOR_LEAP_INVOICE_DOWNLOAD_REQUEST',
  GET_DEBTOR_LEAP_INVOICE_DOWNLOAD_SUCCESS = '@@leapClaims/GET_DEBTOR_LEAP_INVOICE_DOWNLOAD_SUCCESS',
  GET_DEBTOR_LEAP_INVOICE_DOWNLOAD_FAILURE = '@@leapClaims/GET_DEBTOR_LEAP_INVOICE_DOWNLOAD_FAILURE',

  GET_DEBTOR_LEAP_RECEIPT_DOWNLOAD = '@@leapClaims/GET_DEBTOR_LEAP_RECEIPT_DOWNLOAD',
  GET_DEBTOR_LEAP_RECEIPT_DOWNLOAD_REQUEST = '@@leapClaims/GET_DEBTOR_LEAP_RECEIPT_DOWNLOAD_REQUEST',
  GET_DEBTOR_LEAP_RECEIPT_DOWNLOAD_SUCCESS = '@@leapClaims/GET_DEBTOR_LEAP_RECEIPT_DOWNLOAD_SUCCESS',
  GET_DEBTOR_LEAP_RECEIPT_DOWNLOAD_FAILURE = '@@leapClaims/GET_DEBTOR_LEAP_RECEIPT_DOWNLOAD_FAILURE',

  GET_DEBTOR_LEAP_CLAIM_EVENTS = '@@debtorClaims/GET_DEBTOR_CLAIM_LEAP_EVENTS',
  GET_DEBTOR_LEAP_CLAIM_EVENTS_REQUEST = '@@debtorClaims/GET_DEBTOR_LEAP_CLAIM_EVENTS_REQUEST',
  GET_DEBTOR_LEAP_CLAIM_EVENTS_SUCCESS = '@@debtorClaims/GET_DEBTOR_LEAP_CLAIM_EVENTS_SUCCESS',
  GET_DEBTOR_LEAP_CLAIM_EVENTS_FAILURE = '@@debtorClaims/GET_DEBTOR_LEAP_CLAIM_EVENTS_FAILURE',

  GET_DEBTOR_LEAP_DEBT_COLLECTION_CLAIM_EVENTS = '@@debtorClaims/GET_DEBTOR_CLAIM_LEAP_EVENTS',
  GET_DEBTOR_LEAP_DEBT_COLLECTION_CLAIM_EVENTS_REQUEST = '@@debtorClaims/GET_DEBTOR_LEAP_DEBT_COLLECTION_CLAIM_EVENTS_REQUEST',
  GET_DEBTOR_LEAP_DEBT_COLLECTION_CLAIM_EVENTS_SUCCESS = '@@debtorClaims/GET_DEBTOR_LEAP_DEBT_COLLECTION_CLAIM_EVENTS_SUCCESS',
  GET_DEBTOR_LEAP_DEBT_COLLECTION_CLAIM_EVENTS_FAILURE = '@@debtorClaims/GET_DEBTOR_LEAP_DEBT_COLLECTION_CLAIM_EVENTS_FAILURE',
  
  CLEAR_DEBTOR_LEAP_CLAIMS = '@@leapClaims/CLEAR_DEBTOR_LEAP_CLAIMS'
}

export interface DebtorLeapClaimsState {
  claims: LeapClaim[];
  isLoading: boolean;
}

interface GetLeapClaimsAction { type: ActionTypes.GET_DEBTOR_LEAP_CLAIMS; fetchConfig: FetchConfig; }
interface GetLeapClaimsRequestAction { type: ActionTypes.GET_DEBTOR_LEAP_CLAIMS_REQUEST; id: string; }
interface GetLeapClaimsSuccessAction { type: ActionTypes.GET_DEBTOR_LEAP_CLAIMS_SUCCESS; response: LeapClaim[]; }
interface GetLeapClaimsFailureAction { type: ActionTypes.GET_DEBTOR_LEAP_CLAIMS_FAILURE; }

interface GetLeapClaimCreditDownloadAction { type: ActionTypes.GET_DEBTOR_LEAP_CLAIM_CREDIT_DOWNLOAD; fetchConfig: FetchConfig; }
interface GetLeapClaimCreditDownloadRequestAction { type: ActionTypes.GET_DEBTOR_LEAP_CLAIM_CREDIT_DOWNLOAD_REQUEST; caseNumber: string; }
interface GetLeapClaimCreditDownloadSuccessAction { type: ActionTypes.GET_DEBTOR_LEAP_CLAIM_CREDIT_DOWNLOAD_SUCCESS; response: any; caseNumber: string }
interface GetLeapClaimCreditDownloadFailureAction { type: ActionTypes.GET_DEBTOR_LEAP_CLAIM_CREDIT_DOWNLOAD_FAILURE; error: string }

interface GetLeapDebtCollectionWarningDownloadAction { type: ActionTypes.GET_DEBTOR_LEAP_DEBTCOLLECTIONWARNING_DOWNLOAD; fetchConfig: FetchConfig; }
interface GetLeapDebtCollectionWarningDownloadRequestAction { type: ActionTypes.GET_DEBTOR_LEAP_DEBTCOLLECTIONWARNING_DOWNLOAD_REQUEST; caseNumber: string; }
interface GetLeapDebtCollectionWarningDownloadSuccessAction { type: ActionTypes.GET_DEBTOR_LEAP_DEBTCOLLECTIONWARNING_DOWNLOAD_SUCCESS; response: any; caseNumber: string }
interface GetLeapDebtCollectionWarningDownloadFailureAction { type: ActionTypes.GET_DEBTOR_LEAP_DEBTCOLLECTIONWARNING_DOWNLOAD_FAILURE; error: string }

interface GetLeapInvoiceDownloadAction { type: ActionTypes.GET_DEBTOR_LEAP_INVOICE_DOWNLOAD; fetchConfig: FetchConfig; }
interface GetLeapInvoiceDownloadRequestAction { type: ActionTypes.GET_DEBTOR_LEAP_INVOICE_DOWNLOAD_REQUEST; caseNumber: string; }
interface GetLeapInvoiceDownloadSuccessAction { type: ActionTypes.GET_DEBTOR_LEAP_INVOICE_DOWNLOAD_SUCCESS; response: any; caseNumber: string }
interface GetLeapInvoiceDownloadFailureAction { type: ActionTypes.GET_DEBTOR_LEAP_INVOICE_DOWNLOAD_FAILURE; error: string }

interface GetLeapReceiptDownloadAction { type: ActionTypes.GET_DEBTOR_LEAP_RECEIPT_DOWNLOAD; fetchConfig: FetchConfig; }
interface GetLeapReceiptDownloadRequestAction { type: ActionTypes.GET_DEBTOR_LEAP_RECEIPT_DOWNLOAD_REQUEST; caseNumber: string; }
interface GetLeapReceiptDownloadSuccessAction { type: ActionTypes.GET_DEBTOR_LEAP_RECEIPT_DOWNLOAD_SUCCESS; response: any; caseNumber: string }
interface GetLeapReceiptDownloadFailureAction { type: ActionTypes.GET_DEBTOR_LEAP_RECEIPT_DOWNLOAD_FAILURE; error: string }

interface GetLeapClaimEventsAction { type: ActionTypes.GET_DEBTOR_LEAP_CLAIM_EVENTS; fetchConfig: FetchConfig; }
interface GetLeapClaimEventsRequestAction { type: ActionTypes.GET_DEBTOR_LEAP_CLAIM_EVENTS_REQUEST; claimId: string; }
interface GetLeapClaimEventsSuccessAction { type: ActionTypes.GET_DEBTOR_LEAP_CLAIM_EVENTS_SUCCESS; claimId: string; response: DebtorClaimEvent[]; }
interface GetLeapClaimEventsFailureAction { type: ActionTypes.GET_DEBTOR_LEAP_CLAIM_EVENTS_FAILURE; claimId: string; }

interface GetLeapDebtCollectionClaimEventsAction { type: ActionTypes.GET_DEBTOR_LEAP_DEBT_COLLECTION_CLAIM_EVENTS; fetchConfig: FetchConfig; }
interface GetLeapDebtCollectionClaimEventsRequestAction { type: ActionTypes.GET_DEBTOR_LEAP_DEBT_COLLECTION_CLAIM_EVENTS_REQUEST; caseNumber: string; }
interface GetLeapDebtCollectionClaimEventsSuccessAction { type: ActionTypes.GET_DEBTOR_LEAP_DEBT_COLLECTION_CLAIM_EVENTS_SUCCESS; caseNumber: string; response: DebtorClaimEvent[]; }
interface GetLeapDebtCollectionClaimEventsFailureAction { type: ActionTypes.GET_DEBTOR_LEAP_DEBT_COLLECTION_CLAIM_EVENTS_FAILURE; caseNumber: string; }

interface ClearLeapClaims { type: ActionTypes.CLEAR_DEBTOR_LEAP_CLAIMS; }

export type KnownAction = GetLeapClaimsAction
  | GetLeapClaimsRequestAction
  | GetLeapClaimsSuccessAction
  | GetLeapClaimsFailureAction
  | GetLeapClaimCreditDownloadAction
  | GetLeapClaimCreditDownloadRequestAction
  | GetLeapClaimCreditDownloadSuccessAction
  | GetLeapClaimCreditDownloadFailureAction
  | GetLeapInvoiceDownloadAction
  | GetLeapInvoiceDownloadRequestAction
  | GetLeapInvoiceDownloadSuccessAction
  | GetLeapInvoiceDownloadFailureAction
  | GetLeapDebtCollectionWarningDownloadAction
  | GetLeapDebtCollectionWarningDownloadRequestAction
  | GetLeapDebtCollectionWarningDownloadSuccessAction
  | GetLeapDebtCollectionWarningDownloadFailureAction
  | GetLeapReceiptDownloadAction
  | GetLeapReceiptDownloadRequestAction
  | GetLeapReceiptDownloadSuccessAction
  | GetLeapReceiptDownloadFailureAction
  | GetLeapClaimEventsAction
  | GetLeapClaimEventsRequestAction
  | GetLeapClaimEventsSuccessAction
  | GetLeapClaimEventsFailureAction
  | GetLeapDebtCollectionClaimEventsAction
  | GetLeapDebtCollectionClaimEventsRequestAction
  | GetLeapDebtCollectionClaimEventsSuccessAction
  | GetLeapDebtCollectionClaimEventsFailureAction
  | ClearLeapClaims
  | AddNotificationAction;

let abortController = new AbortController();

export const actionCreators = {
  getLeapClaims: (ids: string[]): AppThunkAction<KnownAction> => dispatch => {
    abortController = new AbortController();
    ids.forEach(id => {
      return dispatch({
        type: ActionTypes.GET_DEBTOR_LEAP_CLAIMS,
        fetchConfig: {
          init: { type: ActionTypes.GET_DEBTOR_LEAP_CLAIMS_REQUEST, id },
          path: `/api/Debtors/${id}/leapClaims`,
          signal: abortController.signal,
          success: (json) => {
            if (json === null) {
              json = [];
            }
            dispatch({ type: ActionTypes.GET_DEBTOR_LEAP_CLAIMS_SUCCESS, response: json });
          },
          failure: (error: string) => {
            dispatch({ type: ActionTypes.GET_DEBTOR_LEAP_CLAIMS_FAILURE });
            if (error !== "AbortError") {
              dispatch({ type: NotificationsStoreActionTypes.ADD_NOTIFICATION, notification: { message: 'Kunne ikke hente debitor sine Leap-krav', variant: 'error' } });
            }
          }
        }
      });
    });
  },
  getLeapEvents: (claimId: string): AppThunkAction<KnownAction> => dispatch => {
    abortController = new AbortController();
    return dispatch({
      type: ActionTypes.GET_DEBTOR_LEAP_CLAIM_EVENTS,
      fetchConfig: {
        init: { type: ActionTypes.GET_DEBTOR_LEAP_CLAIM_EVENTS_REQUEST, claimId: claimId },
        path: `/api/leapclaims/${claimId}/events`,
        signal: abortController.signal,
        success: (json) => {
          dispatch({ type: ActionTypes.GET_DEBTOR_LEAP_CLAIM_EVENTS_SUCCESS, claimId: claimId, response: json });
        },
        failure: (error: string) => {
          dispatch({ type: ActionTypes.GET_DEBTOR_LEAP_CLAIM_EVENTS_FAILURE, claimId: claimId });
          dispatch({ type: NotificationsStoreActionTypes.ADD_NOTIFICATION, notification: { message: `Kunne ikke hente hendelser for faktura ${claimId}`, variant: 'error' } });
        }
      }
    });
  },
  getLeapDebtCollectionEvents: (caseNumber: string): AppThunkAction<KnownAction> => dispatch => {
    abortController = new AbortController();
    return dispatch({
      type: ActionTypes.GET_DEBTOR_LEAP_DEBT_COLLECTION_CLAIM_EVENTS,
      fetchConfig: {
        init: { type: ActionTypes.GET_DEBTOR_LEAP_DEBT_COLLECTION_CLAIM_EVENTS_REQUEST, caseNumber: caseNumber },
        path: `/api/leapclaims/${caseNumber}/debtcollectionevents`,
        signal: abortController.signal,
        success: (response) => {
          dispatch({ type: ActionTypes.GET_DEBTOR_LEAP_DEBT_COLLECTION_CLAIM_EVENTS_SUCCESS, caseNumber: caseNumber, response });
        },
        failure: (error: string) => {
          dispatch({ type: ActionTypes.GET_DEBTOR_LEAP_DEBT_COLLECTION_CLAIM_EVENTS_FAILURE, caseNumber: caseNumber });
          dispatch({ type: NotificationsStoreActionTypes.ADD_NOTIFICATION, notification: { message: `Kunne ikke hente hendelser for inkassosak ${caseNumber}`, variant: 'error' } });
        }
      }
    });
  },
  clearLeapClaims: (): AppThunkAction<KnownAction> => dispatch => {
    dispatch({ type: ActionTypes.CLEAR_DEBTOR_LEAP_CLAIMS });
  },
  downloadLeapReceipt: (caseNumber: string): AppThunkAction<KnownAction> => dispatch => {
    abortController = new AbortController();
    return dispatch({
      type: ActionTypes.GET_DEBTOR_LEAP_RECEIPT_DOWNLOAD,
      fetchConfig: {
        init: { type: ActionTypes.GET_DEBTOR_LEAP_RECEIPT_DOWNLOAD_REQUEST, caseNumber },
        path: `/api/leapclaims/${caseNumber}/receipt`,
        signal: abortController.signal,
        stream: true,
        success: response => {
          dispatch({ type: ActionTypes.GET_DEBTOR_LEAP_RECEIPT_DOWNLOAD_SUCCESS, response: response, caseNumber: caseNumber });
        },
        failure: (error: string) => {
          dispatch({ type: ActionTypes.GET_DEBTOR_LEAP_RECEIPT_DOWNLOAD_FAILURE, error: error });
          dispatch({ type: NotificationsStoreActionTypes.ADD_NOTIFICATION, notification: { message: `Kunne ikke last ned kvittering ${caseNumber}`, variant: 'error' } });
        }
      }
    });
  },
  downloadLeapClaimCredit: (caseNumber: string): AppThunkAction<KnownAction> => dispatch => {
    abortController = new AbortController();
    return dispatch({
      type: ActionTypes.GET_DEBTOR_LEAP_CLAIM_CREDIT_DOWNLOAD,
      fetchConfig: {
        init: { type: ActionTypes.GET_DEBTOR_LEAP_CLAIM_CREDIT_DOWNLOAD_REQUEST, caseNumber },
        path: `/api/leapclaims/${caseNumber}/creditnote`,
        signal: abortController.signal,
        stream: true,
        success: response => {
          dispatch({ type: ActionTypes.GET_DEBTOR_LEAP_CLAIM_CREDIT_DOWNLOAD_SUCCESS, response: response, caseNumber: caseNumber });
        },
        failure: (error: string) => {
          dispatch({ type: ActionTypes.GET_DEBTOR_LEAP_CLAIM_CREDIT_DOWNLOAD_FAILURE, error: error });
          dispatch({ type: NotificationsStoreActionTypes.ADD_NOTIFICATION, notification: { message: `Kunne ikke last ned kredittnota ${caseNumber}`, variant: 'error' } });
        }
      }
    });
  },
  downloadLeapInvoice: (caseNumber: string): AppThunkAction<KnownAction> => dispatch => {
    abortController = new AbortController();
    return dispatch({
      type: ActionTypes.GET_DEBTOR_LEAP_INVOICE_DOWNLOAD,
      fetchConfig: {
        init: { type: ActionTypes.GET_DEBTOR_LEAP_INVOICE_DOWNLOAD_REQUEST, caseNumber },
        path: `/api/leapclaims/${caseNumber}/invoice`,
        signal: abortController.signal,
        stream: true,
        success: response => {
          dispatch({ type: ActionTypes.GET_DEBTOR_LEAP_INVOICE_DOWNLOAD_SUCCESS, response: response, caseNumber: caseNumber });
        },
        failure: (error: string) => {
          dispatch({ type: ActionTypes.GET_DEBTOR_LEAP_INVOICE_DOWNLOAD_FAILURE, error: error });
          dispatch({ type: NotificationsStoreActionTypes.ADD_NOTIFICATION, notification: { message: `Kunne ikke last ned faktura ${caseNumber}`, variant: 'error' } });
        }
      }
    });
  },
  downloadLeapDebtCollectionWarning: (caseNumber: string): AppThunkAction<KnownAction> => dispatch => {
    abortController = new AbortController();
    return dispatch({
      type: ActionTypes.GET_DEBTOR_LEAP_DEBTCOLLECTIONWARNING_DOWNLOAD,
      fetchConfig: {
        init: { type: ActionTypes.GET_DEBTOR_LEAP_DEBTCOLLECTIONWARNING_DOWNLOAD_REQUEST, caseNumber },
        path: `/api/leapclaims/${caseNumber}/debtCollectionWarning`,
        signal: abortController.signal,
        stream: true,
        success: response => {
          dispatch({ type: ActionTypes.GET_DEBTOR_LEAP_DEBTCOLLECTIONWARNING_DOWNLOAD_SUCCESS, response: response, caseNumber: caseNumber });
        },
        failure: (error: string) => {
          dispatch({ type: ActionTypes.GET_DEBTOR_LEAP_DEBTCOLLECTIONWARNING_DOWNLOAD_FAILURE, error: error });
          dispatch({ type: NotificationsStoreActionTypes.ADD_NOTIFICATION, notification: { message: `Kunne ikke last ned inkassovarselet ${caseNumber}`, variant: 'error' } });
        }
      }
    });
  },
};

export const initialState: DebtorLeapClaimsState = { claims: [], isLoading: false};

export const reducer: Reducer<DebtorLeapClaimsState | undefined, KnownAction> = (state: DebtorLeapClaimsState = initialState, action: KnownAction) => {
  switch (action.type) {
    case ActionTypes.GET_DEBTOR_LEAP_CLAIMS_REQUEST:
      return { ...state, isLoading: true };
    case ActionTypes.GET_DEBTOR_LEAP_CLAIMS_SUCCESS:
      return { ...state, claims: state.claims.concat(action.response), isLoading: false };
    case ActionTypes.GET_DEBTOR_LEAP_CLAIMS_FAILURE:
      return { ...state, isLoading: false };
    case ActionTypes.CLEAR_DEBTOR_LEAP_CLAIMS:
      abortController.abort();
      return { ...state, claims: [] };
    case ActionTypes.GET_DEBTOR_LEAP_CLAIM_CREDIT_DOWNLOAD_REQUEST:
      return state;
    case ActionTypes.GET_DEBTOR_LEAP_CLAIM_CREDIT_DOWNLOAD_SUCCESS:
      var url = window.URL.createObjectURL(
        new Blob([action.response]),
      );
      var link = document.createElement('a');
      link.href = url;
      var filename = 'Kredittnota' + action.caseNumber + '.pdf';
      link.setAttribute(
        'download',
        filename,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode!.removeChild(link);
      return state;
    case ActionTypes.GET_DEBTOR_LEAP_CLAIM_CREDIT_DOWNLOAD_FAILURE:
      return state;
    case ActionTypes.GET_DEBTOR_LEAP_INVOICE_DOWNLOAD_REQUEST:
      return state;
    case ActionTypes.GET_DEBTOR_LEAP_INVOICE_DOWNLOAD_SUCCESS:
      var url = window.URL.createObjectURL(
        new Blob([action.response]),
      );
      var link = document.createElement('a');
      link.href = url;
      var filename = 'Faktura' + action.caseNumber + '.pdf';
      link.setAttribute(
        'download',
        filename,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode!.removeChild(link);
      return state;
    case ActionTypes.GET_DEBTOR_LEAP_INVOICE_DOWNLOAD_FAILURE:
      return state;
    case ActionTypes.GET_DEBTOR_LEAP_DEBTCOLLECTIONWARNING_DOWNLOAD_REQUEST:
      return state;
    case ActionTypes.GET_DEBTOR_LEAP_DEBTCOLLECTIONWARNING_DOWNLOAD_SUCCESS:
      var url = window.URL.createObjectURL(
        new Blob([action.response]),
      );
      var link = document.createElement('a');
      link.href = url;
      var filename = 'Inkassovarsel' + action.caseNumber + '.pdf';
      link.setAttribute(
        'download',
        filename,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode!.removeChild(link);
      return state;
    case ActionTypes.GET_DEBTOR_LEAP_DEBTCOLLECTIONWARNING_DOWNLOAD_FAILURE:
      return state;
    case ActionTypes.GET_DEBTOR_LEAP_RECEIPT_DOWNLOAD_REQUEST:
      return state;
    case ActionTypes.GET_DEBTOR_LEAP_RECEIPT_DOWNLOAD_SUCCESS:
      var url = window.URL.createObjectURL(
        new Blob([action.response]),
      );
      var link = document.createElement('a');
      link.href = url;
      var filename = 'Kvittering' + action.caseNumber + '.pdf';
      link.setAttribute(
        'download',
        filename,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode!.removeChild(link);
      return state;
    case ActionTypes.GET_DEBTOR_LEAP_RECEIPT_DOWNLOAD_FAILURE:
      return state;
    case ActionTypes.GET_DEBTOR_LEAP_CLAIM_EVENTS_REQUEST:
      return { ...state, isLoading: true };
    case ActionTypes.GET_DEBTOR_LEAP_CLAIM_EVENTS_SUCCESS:
      const claimIndex = state.claims.findIndex(x => x.eventsReferenceId.toString() === action.claimId);
      return {
        ...state, claims: [
          ...state.claims.slice(0, claimIndex).concat({ ...state.claims[claimIndex], events: union(state.claims[claimIndex].events, action.response)
            .sort((a, b): number => { if (a.eventDate <= b.eventDate) { return 1; } else { return -1; } }), eventsHaveBeenFetched: true }),
          ...state.claims.slice(claimIndex + 1)
        ], isLoading: false
      };
    case ActionTypes.GET_DEBTOR_LEAP_CLAIM_EVENTS_FAILURE:
      return { ...state, isLoading: false };
    case ActionTypes.GET_DEBTOR_LEAP_DEBT_COLLECTION_CLAIM_EVENTS_REQUEST:
      return { ...state, isLoading: true };
    case ActionTypes.GET_DEBTOR_LEAP_DEBT_COLLECTION_CLAIM_EVENTS_SUCCESS:
      const debtcollectionclaimIndex = state.claims.findIndex(x => x.number === action.caseNumber);
      return {
        ...state, claims: [
          ...state.claims.slice(0, debtcollectionclaimIndex).concat({ ...state.claims[debtcollectionclaimIndex], events: union(state.claims[debtcollectionclaimIndex].events, action.response)
            .sort((a, b): number => { if (a.eventDate <= b.eventDate) { return 1; } else { return -1; } }), eventsHaveBeenFetched: true }),
          ...state.claims.slice(debtcollectionclaimIndex + 1)
        ], isLoading: false
      };
    case ActionTypes.GET_DEBTOR_LEAP_DEBT_COLLECTION_CLAIM_EVENTS_FAILURE:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};